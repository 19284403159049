import { render, staticRenderFns } from "./articleIndex.vue?vue&type=template&id=41864684&scoped=true&"
import script from "./articleIndex.vue?vue&type=script&lang=js&"
export * from "./articleIndex.vue?vue&type=script&lang=js&"
import style0 from "./articleIndex.vue?vue&type=style&index=0&id=41864684&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41864684",
  null
  
)

export default component.exports