var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumbs',{attrs:{"page_title":"sound","items":_vm.breadcrumbs}}),_c('v-card',{staticClass:"mx-3 my-5 rounded-lg"},[[_c('v-container',{attrs:{"fluid":""}},[(_vm.hasData)?_c('v-row',{staticClass:"mt-0"},_vm._l((_vm.items),function(item,i){return _c('v-col',{key:i,staticClass:"pl-4 pr-4 pt-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"4","lg":"3"}},[_c('v-card',{staticStyle:{"border-radius":"16px","background-color":"#f0f0f0","box-shadow":"unset"},attrs:{"height":"auto"}},[_c('v-card-text',{staticStyle:{"height":"100%","padding-left":"15px","padding-right":"15px","padding-top":"10px","padding-bottom":"5px"},attrs:{"justify":"center","align":"center"}},[_c('p',{staticClass:"text-center mb-2",staticStyle:{"font-size":"18px","color":"#424242"}},[_vm._v(" "+_vm._s(item.contentName)+" ")]),_c('v-img',{staticClass:"soundimg",attrs:{"src":item.optimizeLink}}),_c('div',{staticClass:"overflow-y-auto mt-4",staticStyle:{"height":"135px","overflow-x":"hidden","overflow-y":"auto"}},_vm._l((item.videoList),function(item1,i){return _c('v-row',{key:i,staticStyle:{"cursor":"pointer"}},[_c('v-col',{staticClass:"pl-1 pr-2 pt-2 pb-1",staticStyle:{"text-align":"left"},attrs:{"cols":"12","sm":"6","xs":"6","md":"9","lg":"9"}},[_c('div',{staticClass:"contentTextStyle",on:{"click":function($event){_vm.PlayMP3(item1, item),
                            _vm.prepareSongList(
                              item1.content_ID,
                              item.videoList,
                              item.lesson_id_no
                            ),
                            _vm.$refs.soundPlay.clickPlayBtn(true)}}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(item1.content_Name))])])]),_c('v-col',{staticClass:"pl-2 pr-3 pt-2 pb-2",staticStyle:{"text-align":"end"},attrs:{"cols":"12","sm":"6","xs":"6","md":"3","lg":"3"}},[_c('v-progress-circular',{staticClass:"mx-auto my-auto center playIcon",attrs:{"rotate":-90,"size":30,"width":3,"value":item1.progressTime * 100,"color":"#4FB14E"},on:{"click":function($event){_vm.PlayMP3(item1, item),
                            _vm.prepareSongList(
                              item1.content_ID,
                              item.videoList,
                              item.lesson_id_no
                            ),
                            _vm.$refs.soundPlay.clickPlayBtn(true)}}},[_c('v-icon',{staticStyle:{"font-size":"20px","color":"#cccccc","padding-left":"3px","padding-top":"2px","margin-bottom":"3px"}},[_vm._v("mdi-play-outline")])],1)],1)],1)}),1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end align-center pt-3 pr-2 pb-1",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("enddate") + " : " + item.endDateOfContent)+" ")])],1)],1)],1)],1)}),1):(_vm.loading)?_c('v-row',{staticClass:"mt-0"},_vm._l((3),function(n){return _c('v-col',{key:n,staticClass:"pl-4 pr-4",attrs:{"cols":"12","xs":"12","sm":"12","md":"4","lg":"3"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",staticStyle:{"height":"370px","border":"solid 1px #f0f0f0","padding":"10px"},attrs:{"type":"text,image,list-item-two-line"}})],1)}),1):_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-center nodata"},[_vm._v(_vm._s(_vm.$t("nosound")))])])],1)],1)]],2),_c('soundPlay',{ref:"soundPlay",attrs:{"show":_vm.showSound,"audioName":_vm.audioName,"audioDescription":_vm.audioDescription,"dialog":_vm.dialog,"viewerFlag":_vm.viewerFlag,"audioFile":_vm.contentFile,"audioImg":_vm.audioImg,"contentid":_vm.contentId,"currentminutes":_vm.currentminutes,"playListOpt":_vm.songList,"memberID":_vm.memberID},on:{"close":function($event){(_vm.showSound = false), _vm.GetSoundData()},"show_sound":_vm.show_sound,"updateProgressCircle":_vm.updateProgressCircle,"skipToNext":_vm.playNextSong,"skipToPrevious":_vm.playPreviousSong}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }