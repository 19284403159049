<template>
  <div>

    <Article v-if="$route.params.subName =='Article'"/>

    <Sound v-if="$route.params.subName =='Sound'"/>

    <Video v-if="$route.params.subName =='Video'"/>

  </div>
</template>

<script>
import Article from "./_article/articleIndex.vue";
import Sound from "./_sound/soundIndex.vue";
import Video from "./_video/videoIndex.vue";
export default {
  components: {
    Article,
    Sound,
    Video
  },
  data() {    
    return {
      item_No: 0,
      // breadcrumbs: [
      //   {
      //     sidebar_tilte: "home"
      //   },
      //   {
      //     text: "Library",
      //     href: "/library"
      //   },
      //   {
      //     text: this.$route.query.subName
      //   }
      // ]
    };
  },
  methods: {
  }
};
</script>